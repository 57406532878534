<template>
  <v-tooltip v-if="message" top>
    <template #activator="{ on }">
      <v-chip
        v-on="on"
        :color="color"
        outlined
        small
        v-text="$t(`status.dgi.${status}`)"
      />
    </template>
    <span v-text="message" />
  </v-tooltip>
  <v-chip
    v-else-if="status"
    :color="color"
    outlined
    small
    v-text="$t(`status.dgi.${status}`)"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { Invoice } from "@planetadeleste/vue-mc-gw";
import { get } from "lodash";

@Component
export default class InvoiceDgiStatus extends Vue {
  @Prop(Object) readonly invoice!: Invoice | InvoiceData;

  get status(): string | null {
    return this.invoice && this.invoice instanceof Invoice
      ? this.invoice.get("status_dgi")
      : get(this.invoice, "status_dgi", null);
  }

  get color() {
    if (!this.status) {
      return null;
    }

    // ACCEPTED get green color
    if (this.status === "00") {
      return "green";
    }

    // WAITING response from DGI
    if (this.status === "11") {
      return "teal";
    }

    // REJECTED get red color
    if (["01", "05"].includes(this.status)) {
      return "red";
    }

    return "orange";
  }

  get message() {
    return !this.status || ["00", "01", "05"].includes(this.status)
      ? null
      : this.$t(`status.dgi.message.${this.status}`);
  }
}
</script>
